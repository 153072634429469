import { Injectable } from '@angular/core';
import { IMarkerStrategy } from './marker-strategy';
import { HighFlowMarkerStrategy } from './highflow-marker-strategy';
import { CommonMarkerStrategy } from './common-marker-strategy';

@Injectable({
  providedIn: 'root',
})
export class MarkerStrategyFactory {
  createMarkerStrategy(highFlow: boolean): IMarkerStrategy {
    switch (highFlow) {
      case true:
        return new HighFlowMarkerStrategy();
      default:
        return new CommonMarkerStrategy();
    }
  }
}
