import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MapSettings {
  defaultOptions(): google.maps.MapOptions {
    return {
      mapId: 'map',
      scrollwheel: true,
      keyboardShortcuts: false,
      mapTypeControl: false,
      disableDoubleClickZoom: true,
      center: { lat: -23.5505199, lng: -46.6333094 },
      maxZoom: 15,
    };
  }
}
