import { BaseMarkerStrategy } from './base-marker-strategy';

export class CommonMarkerStrategy extends BaseMarkerStrategy {
  protected getPinColor(): string {
    return '#E74646';
  }
  protected getGlyphColor(): string {
    return 'white';
  }
  protected getPinScale(): number {
    return 1.2;
  }
}
