import { Injectable } from '@angular/core';
import { MarkerStrategyFactory } from './marker-strategy-factory';
import { IMarkerData } from './marker-data';
import { LocalType } from './local-type.enum';
import { InfoWindowService } from '../info-window/info-window.service';

@Injectable({
  providedIn: 'root',
})
export class MarkerService {
  private currentInfoWindow: google.maps.InfoWindow | null = null;

  constructor(
    private readonly markerStrategyFactory: MarkerStrategyFactory,
    private readonly infoWindowService: InfoWindowService
  ) {}

  async createMarkerAsync(
    data: IMarkerData,
    map?: google.maps.Map
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    const marker = await this.createAndConfigureMarker(data, map);
    this.addClickListenerToMarker(marker, data);
    return marker;
  }

  private async createAndConfigureMarker(
    data: IMarkerData,
    map?: google.maps.Map
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    const markerStrategy = this.markerStrategyFactory.createMarkerStrategy(
      data.highFlow ?? false
    );
    const pinElement = await markerStrategy.createPinElement();

    this.attachLabelToPin(pinElement, data);

    return this.createAdvancedMarkerElement(pinElement, data, map);
  }

  private attachLabelToPin(
    pinElement: google.maps.marker.PinElement,
    data: IMarkerData
  ): void {
    const label = this.createLabel(data);
    pinElement.element.appendChild(label);
  }

  private createLabel(data: IMarkerData): HTMLElement {
    const classColor = data.highFlow ? 'primary' : 'error';
    const label = document.createElement('div');
    label.classList.add('label', classColor);
    label.textContent = data.name ?? '';
    return label;
  }

  private async createAdvancedMarkerElement(
    pinElement: google.maps.marker.PinElement,
    data: IMarkerData,
    map?: google.maps.Map
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;

    return new AdvancedMarkerElement({
      position: new google.maps.LatLng(
        Number(data.latitude),
        Number(data.longitude)
      ),
      title: data.name,
      map: map,
      content: pinElement.element,
    });
  }

  private addClickListenerToMarker(
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData
  ): void {
    marker.addListener('click', () => this.handleMarkerClick(marker, data));
  }

  private handleMarkerClick(
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData
  ): void {
    this.closeCurrentInfoWindow();
    this.openNewInfoWindow(marker, data);
  }

  private closeCurrentInfoWindow(): void {
    if (this.currentInfoWindow) {
      this.currentInfoWindow.close();
    }
  }

  private openNewInfoWindow(
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData
  ): void {
    this.currentInfoWindow = new google.maps.InfoWindow({
      content: this.infoWindowService.createContent(data, LocalType.GasStation),
    });
    this.currentInfoWindow.open(marker.map, marker);
  }
}
