import { IMarkerStrategy } from './marker-strategy';

export abstract class BaseMarkerStrategy implements IMarkerStrategy {
  protected abstract getPinColor(): string;
  protected abstract getGlyphColor(): string;
  protected abstract getPinScale(): number;

  async createPinElement(): Promise<google.maps.marker.PinElement> {
    const { PinElement } = (await google.maps.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;

    return new PinElement({
      background: this.getPinColor(),
      glyphColor: this.getGlyphColor(),
      borderColor: this.getPinColor(),
      scale: this.getPinScale(),
    });
  }
}
