import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IMarkerData } from '../marker/marker-data';
import { LocalType } from '../marker/local-type.enum';

@Injectable({
  providedIn: 'root',
})
export class InfoWindowService {
  constructor(private readonly translateService: TranslateService) {}

  createContent(data: IMarkerData, localType?: LocalType): string {
    const address = this.createAddress(data);
    const mapLink = this.createMapLink(data, localType);
    const directionLink = this.createDirectionLink(data, localType);

    return `
      <div class="info-window-content">
        <div>
          <div>
            <div class="info-window-content__name">
              ${data.name}
            </div>
            ${address}
          </div>
          ${mapLink}
        </div>
        <div>
          ${directionLink}
        </div>
      </div>
    `;
  }

  private createAddress(data: IMarkerData): string {
    return `
      <div class="info-window-content__address">
        <div>${data.address} -</div>
        <div>
          ${data.area}, ${data.city} - ${data.state},
        </div>
        <div>${data.postalCode}</div>
      </div>
    `;
  }

  private createMapLink(data: IMarkerData, localType?: LocalType): string {
    const url = 'https://www.google.com/maps/search/?api=1&query=';

    return `
      <div>
        <a target="_blank" href="${url}${data.address},${data.area},${
      data.city
    },${data.localName},${data.localName ? localType : ''}">
          ${this.translateService.instant('@VEHICLES-CONTENT-MAPS-LABEL-1')}
        </a>
      </div>
    `;
  }

  private createDirectionLink(
    data: IMarkerData,
    localType?: LocalType
  ): string {
    const url = 'https://maps.google.com/maps/dir//';

    return `
      <div>
        <a target="_blank" href="${url}${data.address},${data.area},${
      data.city
    },${data.localName},${localType}">
          <div class="info-window-content__navigate"></div>
          <div>${this.translateService.instant(
            '@VEHICLES-CONTENT-MAPS-LABEL-2'
          )}</div>
        </a>
      </div>
    `;
  }
}
