import { Injectable } from '@angular/core';
import { MapFactory } from './map/map-factory';
import { MarkerService } from './marker/marker.service';
import { MapSettings } from './map/map-settings';
import { IMarkerData } from './marker/marker-data';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  constructor(
    private readonly mapFactory: MapFactory,
    private readonly markerService: MarkerService,
    private readonly mapSettings: MapSettings
  ) {}

  async initMapAsync(elementId: string): Promise<google.maps.Map> {
    return await this.mapFactory.createMapAsync(elementId);
  }

  async createMarkerAsync(
    data: IMarkerData,
    map?: google.maps.Map
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    return await this.markerService.createMarkerAsync(data, map);
  }

  centerMap(
    markers: google.maps.marker.AdvancedMarkerElement[],
    map?: google.maps.Map
  ): void {
    if (!markers.length || !map) return;

    const bounds = new google.maps.LatLngBounds();
    markers.forEach((marker) => bounds.extend(marker.position!));

    map.fitBounds(bounds);
    this.setMaxZoom(map);
  }

  private setMaxZoom(map: google.maps.Map): void {
    const defaultZoom = 15;
    const zoomMap = map.getZoom() ?? defaultZoom;
    const maxZoom = this.mapSettings.defaultOptions().maxZoom ?? defaultZoom;

    if (zoomMap > maxZoom) {
      map.setZoom(maxZoom);
    }
  }
}
