import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { IMarkerData } from '@shared/services/google-maps/marker/marker-data';
import { GoogleMapsService } from '@shared/services/google-maps/google-maps.service';

@Component({
  selector: 'qsc-google-maps',
  standalone: true,
  imports: [CommonModule, GoogleMapsModule],
  providers: [TitleCasePipe],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit, OnChanges {
  @Input() data: IMarkerData[] = [];

  map: google.maps.Map | undefined;
  markers: google.maps.marker.AdvancedMarkerElement[] = [];

  constructor(
    private readonly googleMapsService: GoogleMapsService,
    private readonly titleCasePipe: TitleCasePipe
  ) {}

  async ngOnInit(): Promise<void> {
    await this.googleMapsService.initMapAsync('map').then(async (map) => {
      this.map = map;

      if (this.data.length > 0) {
        await this.updateMarkers();
        this.googleMapsService.centerMap(this.markers, this.map);
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['data'] && this.map) {
      await this.updateMarkers();

      setTimeout(async () => {
        this.googleMapsService.centerMap(this.markers, this.map);
      }, 200);
    }
  }

  private async updateMarkers(): Promise<void> {
    if (this.map) {
      this.clearMarkers();
      const filteredLocations = this.filterLocations(this.data);
      if (filteredLocations.length > 0) {
        this.markers = await Promise.all(
          filteredLocations.map(async (data: IMarkerData) =>
            this.googleMapsService.createMarkerAsync(data, this.map)
          )
        );
        this.googleMapsService.centerMap(this.markers, this.map);
      }
    }
  }

  private filterLocations(data: IMarkerData[]): IMarkerData[] {
    return data.filter(
      (data) => data.latitude && data.longitude && data.visible
    );
  }

  private clearMarkers(): void {
    this.markers.forEach((marker) => (marker.map = null));
    this.markers = [];
  }

  public transform(data: IMarkerData): void {
    this.titleCasePipe.transform(data.name);
    this.titleCasePipe.transform(data.address);
    this.titleCasePipe.transform(data.area);
    this.titleCasePipe.transform(data.city);
  }
}
